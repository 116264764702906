import { useEffect, useState } from 'react';
import styled from 'styled-components';
// hooks
import useRedirectUrl from 'hooks/domain/review/useRedirectUrl';
import { isExistWindow } from 'lib/utils/helper';
// styles
import Icon from 'cds/icons';
import { mediaQuery, palette } from 'cds/styles';

const SignInSocial = ({ onSignup, onSignBizEmail }) => {
  const [queryParams, setQueryParams] = useState('');

  const { redirectUrl } = useRedirectUrl();

  /**
   * 소셜 로그인 분기
   * @param {object} params
   * @param {'kakao' | 'facebook' | 'naver' | 'apple'} params.type
   */
  const onSocialLogin = ({ type }) => {
    const url = queryParams.slice(5);
    const isFullUrl = url.includes('http');
    const newURL = isFullUrl ? url : `https://www.classu.co.kr${url}`;
    const prefixURL = window.location.origin;
    const removePrefix = prefixURL + '/';
    const _url = new URL(prefixURL + queryParams);
    _url.searchParams.delete('url');
    _url.searchParams.append('url', newURL);
    const newQueryParams = decodeURIComponent(_url.toString()).replace(removePrefix, '');
    window.open(
      `/modoo/auth/${type}${newQueryParams}`,
      `signinWin`,
      `width=445,height=550,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,left=50,top=100`,
    );
  };

  /** 리턴 url 설정 */
  useEffect(() => {
    if (!isExistWindow()) {
      return;
    }

    const params = window.location.search;
    if (params) {
      setQueryParams(params);
      return;
    }

    if (redirectUrl === '/new/user') {
      return;
    }

    setQueryParams(`?url=${redirectUrl}`);
  }, [redirectUrl]);

  return (
    <>
      <Section>
        <Inner>
          <Title>지금 바로 시작하기</Title>

          <KakaoButton onClick={() => onSocialLogin({ type: 'kakao' })}>
            <Icon name="ico_kakao" width={36} height={36} />
            카카오로 시작하기
          </KakaoButton>

          <NaverButton onClick={() => onSocialLogin({ type: 'naver' })}>
            <Icon name="ico_naver" width={36} height={36} />
            네이버로 시작하기
          </NaverButton>

          <AppleButton onClick={() => onSocialLogin({ type: 'apple' })}>
            <Icon name="ico_apple" width={36} height={36} />
            애플로 시작하기
          </AppleButton>

          <FacebookButton onClick={() => onSocialLogin({ type: 'facebook' })}>
            <Icon name="ico_meta" width={30} height={16} />
            메타로 시작하기
          </FacebookButton>

          <PhoneButton onClick={onSignup}>
            <Icon name="ico_phone_2" width={20} height={24} />
            휴대폰 번호
          </PhoneButton>

          <BizEmailButton onClick={onSignBizEmail}>Biz 회원 로그인</BizEmailButton>
        </Inner>
      </Section>
    </>
  );
};

const Section = styled.section`
  width: 100%;
  max-width: 530px;
  padding: 16px;
  margin: 0 auto;
`;

const Inner = styled.div`
  max-width: 386px;
  margin: 0 auto;
`;

const Title = styled.h2`
  margin-bottom: 50px;
  font-size: 21px;
  font-weight: 700;
  line-height: 34px;
  text-align: center;
  color: ${palette.grey75};
  ${mediaQuery.large} {
    font-size: 24px;
    line-height: 42px;
  }
`;

const LoginButton = styled.button`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54px;
  margin: 0 auto 14px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 200px;
  font-size: 13px;
  color: ${palette.white};

  & svg {
    position: absolute;
    left: 20px;
  }
`;

const KakaoButton = styled(LoginButton)`
  background-color: #fae248;
  color: ${palette.black};
`;

const NaverButton = styled(LoginButton)`
  background-color: #5cc33a;
`;

const AppleButton = styled(LoginButton)`
  border: 1px solid ${palette.grey0};
`;

const FacebookButton = styled(LoginButton)`
  background-color: #3a589e;
`;

const PhoneButton = styled(LoginButton)`
  background-color: ${palette.grey75};
  color: ${palette.black};
  & svg {
    position: absolute;
    left: 24px;
  }
`;

const BizEmailButton = styled(LoginButton)`
  background-color: ${palette.grey0};
  color: ${palette.grey75};
`;

export default SignInSocial;
