import { useState, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
// utils
import deviceChecker from 'lib/utils/deviceChecker';

/**
 * 비로그인시 redirect url을 설정한다.
 *
 * @example
 * const { redirectUrl, setRedirectUrl } = useRedirectUrl();
 * ${process.env.NEXT_PUBLIC_DEFAULT_HOST}/modoo/storeLogin?url=${redirectUrl};
 * setRedirectUrl({type: ...});
 */
const useRedirectUrl = () => {
  const router = useRouter();
  const { classId } = router.query;
  const [url, setUrl] = useState('');

  /**
   * 로컬스토리지에 redirect type을 저장한다.
   * @param {object} props
   * @param {'share' | 'payment' | string} props.type
   */
  const setRedirectUrl = useCallback(({ type }) => {
    const device = deviceChecker();
    if (!device.app()) {
      window.localStorage.removeItem('redirectType');
      window.localStorage.setItem('redirectType', type);
    }
  }, []);

  useEffect(() => {
    const device = deviceChecker();
    if (device.app()) {
      return;
    }

    const redirectType = window.localStorage.getItem('redirectType');
    if (!redirectType) {
      return;
    }
    if (redirectType === 'share') {
      return setUrl(window.location.pathname);
    }
    if (redirectType === 'payment') {
      return setUrl(`/class/pay/payinfo/${classId}`);
    }
    if (redirectType === 'reload') {
      return setUrl(window.location.pathname);
    }
    if (/^https?/i.test(redirectType)) {
      return setUrl(redirectType);
    }
  }, [router, classId, setRedirectUrl]);

  return {
    setRedirectUrl,
    redirectUrl: url,
  };
};

export default useRedirectUrl;
