import { useEffect } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
// components
import SpinLoading from 'components/atoms/common/SpinLoading';
import HeadLayout from 'components/layout/Head';
import SignMobileHeader from 'components/molecules/headers/SignMobileHeader';
import SignPCHeader from 'components/molecules/headers/SignPCHeader';
import SignInSocial from 'components/molecules/sign/SigninSocial';
// hooks
import { useSelector } from 'hooks/common/useStore';
// styles
import Icon from 'cds/icons';
import { mediaQuery, palette } from 'cds/styles';

// 커뮤니티를 위한 작업
import { getToken } from 'api/user';
import { useDispatch } from 'hooks/common/useStore';
import { getUserData } from 'slices/user.thunk';
import { updateInfo } from 'slices/user';

/**
 * 소셜 로그인 페이지
 */
const LoginPage = () => {
  const router = useRouter();

  const dispatch = useDispatch();

  const userInfo = useSelector(state => state.user.info);

  /**
   * 토큰 문제가 있는 사용자의 경우 로그아웃 페이지로 이동합니다.
   * 쿠키 삭제 처리가 이루어지며, 로그인 페이지로 이동합니다.
   */

  const setLogout = () => {
    if (typeof window === 'undefined') {
      return;
    }

    window.location.href = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/modoo/logout`;
  };

  /** 로그인 페이지로 이동 */
  const onSignup = () => {
    const urlParams = router.query.url;
    const { url, ...otherParams } = router.query;

    const queryString = Object.keys(otherParams)
      .map(key => `&${key}=${encodeURIComponent(otherParams[key])}`)
      .join('');

    const signinUrl = urlParams ? `/user/signin?url=${urlParams}${queryString}` : `/user/signin`;
    router.push(signinUrl);
  };

  /** 유저 정보가 있으면 이전 페이지로 이동한다.*/
  useEffect(() => {
    if (!userInfo.isLoaded || !userInfo.id) {
      return;
    }
    // router.isReady()
    // URL 쿼리 파라미터 여부에 따른 페이지 이동 처리
    const urlParams = router.query.url;

    // console.log('urlParams = ', urlParams);
    // //token
    // if (urlParams.includes('community')) {
    //   let token; // 토큰 선언
    //   getToken()
    //     .then(async res => {
    //       token = res.token;
    //       if (!token) {
    //         return;
    //       }
    //       window.localStorage.removeItem('token');
    //       window.localStorage.removeItem('user_id');
    //       window.localStorage.setItem('token', token);
    //       window.localStorage.setItem('user_id', userInfo.id);

    //       console.log('Token:', token); // 콘솔 로그 추가
    //       console.log('user_id:', userInfo.id); // 콘솔 로그 추가

    //       //   (async () => {
    //       //     const res = await dispatch(getUserData());
    //       //     if (res.payload === 470) {
    //       //       setLogout();
    //       //     }
    //       //   })();
    //       // })
    //       return dispatch(getUserData()).then(res => {
    //         if (res.payload === 470) {
    //           setLogout();
    //         }
    //       });
    //     })
    //     .then(() => {
    //       console.log('Redirect URL:', absoluteUrl); // 콘솔 로그 추가
    //       const redirectUrl = urlParams + '&token=' + token;
    //       const absoluteUrl = 'https://dev-home.classu.co.kr/auth/route?urlrow=' + redirectUrl;
    //       router.replace(absoluteUrl);
    //     })
    //     .catch(() =>
    //       dispatch(
    //         updateInfo({
    //           isLoaded: true,
    //         }),
    //       ),
    //     );
    // }
    //
    const redirectUrl = urlParams
      ? urlParams === '/new'
        ? '/'
        : urlParams
      : `/user/${userInfo.id}`;
    router.replace(redirectUrl);
  }, [router, userInfo, dispatch]);

  /** 폰 가입 페이지로 이동 */
  const onSignBizEmail = () => {
    const urlParams = router.query.url;
    const { url, ...otherParams } = router.query;

    const queryString = Object.keys(otherParams)
      .map(key => `&${key}=${encodeURIComponent(otherParams[key])}`)
      .join('');

    const signupUrl = urlParams
      ? `/user/biz/signin/email?url=${urlParams}${queryString}`
      : `/user/biz/signin/email`;
    router.push(signupUrl);
  };
  if (!userInfo.isLoaded || userInfo.id) {
    return (
      <LoadingLayout>
        <SpinLoading />
      </LoadingLayout>
    );
  }

  return (
    <>
      <HeadLayout mobile={<SignMobileHeader />} pc={<SignPCHeader />} />

      <Main>
        <Section>
          <Inner>
            <SignInSocial onSignup={onSignup} onSignBizEmail={onSignBizEmail} />
            <br />
            <br />
          </Inner>
        </Section>
      </Main>
    </>
  );
};

const LoadingLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 128px;
`;

const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;

  ${mediaQuery.large} {
    padding: 100px 0;
  }
`;

const Section = styled.section`
  width: 100%;
  max-width: 530px;
  padding: 16px;
`;

const Inner = styled.div`
  max-width: 386px;
  margin: 0 auto;
`;

const LoginButton = styled.button`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54px;
  margin: 0 auto 14px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
  font-size: 13px;
  color: ${palette.white};

  & svg {
    position: absolute;
    left: 4px;
  }
`;

export default LoginPage;
